import styles from './index.module.scss'
import cx from 'classnames'

function About() {

  return <div className={styles.page}>
    <div className={styles.section1}>
      <img className={styles.bg} src="/images/aboutBg.png" alt="" />
      <div className={styles.textWrap}>
        <div data-aos="fade-left">
          <div className={styles.row}>DIN is the</div>
          <div className={styles.row}>Data Intelligence Network that</div>
          <div className={styles.row}>Empowers AI with Crypto</div>
        </div>
        <div className={styles.smallText} data-aos="fade-left" data-aos-delay="300">The first network with protocols and communities for the revolution of data flow and AI agent building.</div>
      </div>
    </div>
    <div className={styles.section2}>
      <div className={styles.title}>
        <span className={styles.splitL}></span>
        <span className={styles.text}>Backed By</span>
        <span className={styles.splitR}></span>
      </div>
      <div className={styles.icons}>
        <img src="/images/b1.png" alt="" />
        <img src="/images/b2.png" alt="" />
        <img src="/images/b3.png" alt="" />
        <img src="/images/b4.png" alt="" />
        <img src="/images/b5.png" alt="" />
        <img src="/images/b6.png" alt="" />
        <img src="/images/b7.png" alt="" />
        <img src="/images/b8.png" alt="" />
      </div>
    </div>
    <div className={styles.section3}>
      <div className={styles.title}>
        How DIN works
      </div>
      <div className={styles.content}>
        <img src="/images/workflow.png" alt="" />
      </div>
    </div>
    <div className={styles.section4}>
      <div className={styles.title}>Why a new “AI+Crypto”</div>
      <div className={styles.list}>
        <div className={styles.item}>
          <img src="/images/w1.png" alt="" />
          <span>Data Collaboration</span>
        </div>
        <div className={styles.item}>
          <img src="/images/w2.png" alt="" />
          <span>Fair Compensation</span>
        </div>
        <div className={styles.item}>
          <img src="/images/w3.png" alt="" />
          <span>Ownership</span>
        </div>
        <div className={styles.item}>
          <img src="/images/w4.png" alt="" />
          <span>Intent-Centric</span>
        </div>
      </div>
    </div>
  </div >
}

export default About;
