import styles from './index.module.scss'
import cx from 'classnames'
import { Link, Outlet } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Modal from 'react-modal';
import React from 'react';
function Layout() {
  const [showNodeEntry, setShowNodeEntry] = React.useState(true);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isPrivacy, setIsPrivacy] = React.useState(true);
  function closeModal() {
    setIsOpen(false);
  }
  const openPrivacyModal = () => {
    setIsOpen(true);
    setIsPrivacy(true)
  }
  const openTermsModal = () => {
    setIsOpen(true);
    setIsPrivacy(false)
  }
  useEffect(() => {
    AOS.init({
      offset: -200
    });
  }, [])
  return <div className={styles.layoutPage}>
    {
      showNodeEntry && <a href="https://node.din.lol/" target='_blank' className={cx(styles.nodeEntry, 'hover-item')}>
        <img src="/images/entrance2.png" alt="" />
        <svg onClick={(e) => { e.preventDefault(); setShowNodeEntry(false) }} className='hover-item' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.125 3.2207L16.8184 1.91406L10.0195 8.71289L3.2207 1.91406L1.91406 3.2207L8.71289 10.0195L1.91406 16.8184L3.2207 18.125L10.0195 11.3262L16.8184 18.125L18.125 16.8184L11.3262 10.0195L18.125 3.2207Z" fill="#131313" />
        </svg>
      </a>
    }

    <div className={styles.header}>
      <Link to="/">
        <img className={styles.logo} src="/images/logo.png" alt="" />
      </Link>
      <div className={styles.hRight}>
        <Link to="/about" className={cx(styles.item, 'hvr-underline-from-center')}>About</Link>
        <Link to="/products" className={cx(styles.item, 'hvr-underline-from-center')}>Product</Link>
        <Link to="/career" className={cx(styles.item, 'hvr-underline-from-center')}>Career</Link>
        <a href="https://dinlol.gitbook.io/din-cook-data-for-ai " target="_blank" className={cx(styles.item, 'hvr-underline-from-center')}>
          <span>Docs</span>
          <img src="/images/share.png" alt="" />
        </a>
        <a href="https://dinlol.medium.com/ " target="_blank" className={cx(styles.item, 'hvr-underline-from-center')}>
          <span>Blog</span>
          <img src="/images/share.png" alt="" />
        </a>
      </div>
    </div>
    <div className={cx(styles.main, { [styles.showNodeEntry]: showNodeEntry })}>
      <Outlet />
    </div>
    <div className={styles.footer}>
      <div className={styles.inner}>
        <a href="https://dinlol.medium.com/ " target="_blank" className={cx(styles.subscribe, 'hover-item')}>
          Subscribe Newsletter &gt;
        </a>
        <div className={styles.icons}>
          <a className='hover-item' href="https://twitter.com/din_lol_" target="_blank">
            <img src="/images/twitter.png" alt="" />
          </a>
          <a className='hover-item' href="https://discord.gg/dinlol" target="_blank">
            <img src="/images/discord.png" alt="" />
          </a>
          <a className='hover-item' href="https://t.me/DINCommunity " target="_blank">
            <img src="/images/telegram.png" alt="" />
          </a>
          <a className='hover-item' href="https://dinlol.medium.com/ " target="_blank">
            <img src="/images/medium.png" alt="" />
          </a>
          <a className='hover-item' href="https://www.youtube.com/@din_lol_  " target="_blank">
            <img src="/images/youtube.png" alt="" />
          </a>
          <a className='hover-item' href="https://dinlol.gitbook.io/din-cook-data-for-ai " target="_blank">
            <img src="/images/book.png" alt="" />
          </a>
          <a className='hover-item' href="https://github.com/web3go-xyz" target="_blank">
            <img src="/images/github.png" alt="" />
          </a>
        </div>
        <div className={styles.fr}>
          <div className={styles.frb}>
            {/* <i className='hover-item' onClick={openPrivacyModal}>privacy policy</i>
            <i className='hover-item' onClick={openTermsModal}>terms and conditions</i> */}
            <a className='hover-item' href="https://din.lol/privacy-policy.html" target='_blank'>Privacy Policy</a>
            <a className='hover-item' href="https://din.lol/terms-conditions.html" target='_blank'>Terms and Conditions</a>
          </div>
        </div>
      </div>
    </div>
    <Modal
      overlayClassName={styles.Overlay}
      className={styles.modal}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
    >
      <img className={cx(styles.close, 'hover-item')} src="/images/close.png" onClick={closeModal} alt="" />
      <div className={styles.scroll}>
        {
          isPrivacy ?
            <>
              <div className={styles.title}>Privacy Policy</div>
              <div>
                <h2>Last updated: May 15th, 2024</h2>
                <p>
                  Please read this Privacy Policy carefully before using the DIN
                  websites, including (https://din.lol),(http://xdata.din.lol) and all
                  other subdomains (the "Service"). This
                  Privacy Policy outlines which of your personal data we collect, how we
                  use this data and your data privacy rights as a user of the Service.
                </p>
                <p>
                  This Privacy Policy document contains types of information that is
                  collected and recorded by DIN and how we use it. In this Privacy
                  Policy, by personal data we mean information that identifies you as an
                  individual. Personal data does not include non-personally identifying
                  information. The Privacy Policy applies only to our online activities
                  and is valid for visitors to our website with regards to the information
                  that they share and/or collect in DIN. This policy is not applicable
                  to any information collected offline or via channels other than this
                  website.
                </p>
                <h2>CONSENT</h2>
                <p>
                  By using our service, you hereby consent to our Privacy Policy and agree
                  to its terms.
                </p>
                <p>NON-PERSONALLY IDENTIFYING INFORMATION</p>
                <p>
                  Our purpose in collecting non-personally identifying information is to
                  better understand how visitors use our website. We do not use
                  non-personally identifying information in a way that would enable us to
                  identify you personally.
                </p>
                <h2>Log files</h2>
                <p>
                  DIN follows a standard procedure of using log files. information
                  collected by log files include internet protocol (IP) addresses, browser
                  type, Internet Service Provider (ISP), date and time stamp,
                  referring/exit pages, language preference and possibly the number of
                  clicks. These are not linked to any information that is personally
                  identifiable. The purpose of the information is for analyzing trends,
                  administering the site, tracking users' movement on the website, and
                  gathering demographic information.
                </p>
                <h2>Aggregated statistics</h2>
                <p>
                  We may collect statistics about the behaviour of visitors and users of
                  the Service. For instance, we may monitor the most popular dashboards or
                  queries. We may display this information publicly or provide it to
                  others.
                </p>
                <h2>PERSONAL DATA</h2>
                <p>
                  You may choose to interact with the Service in ways that require us to
                  gather your personal data. The amount and type personal data that we
                  gather depends on the nature of the interaction. You may always refuse
                  to supply personal data, with the caveat that it may prevent you from
                  using certain parts of the Service.
                </p>
                <p>Personal data includes:</p>
                <p>
                  1, signing up and interaction information: email address, wallet
                  addresses, twitter account, etc,.<br />
                  2, marketing materials’ information: we may occasionally send you emails
                  to tell you about new features, solicit your feedback, or just keep you
                  up to date with what’s going on with our products. You can unsubscribe
                  at any time by clicking the "Unsubscribe" button in our emails.<br />
                  3, billing information: in case you subscribe paid services on DIN,
                  the billing information you provide for payment processing.<br />
                </p>
                <h2>Cookies</h2>
                <p>
                  Cookies are text files placed on your computer to collect standard
                  Internet log information and visitor behavior information. For further
                  information, visit all aboutcookies.org. We use cookies to help us track
                  your usage of our website, and your website access preferences. If you
                  do not wish to have cookies placed on your computer, you may set your
                  browser to refuse cookies before using our websites, with the caveat
                  that certain features of our websites may not function properly without
                  the aid of cookies.
                </p>
                <h2>Use of personal data</h2>
                <p>
                  We use the information we collect in various ways, including to:<br />
                  - Provide, operate, and maintain our website<br />
                  - Improve, personalize, and expand our website<br />
                  - Understand and analyze how you use our website<br />
                  - Develop new products, services, features, and functionalities<br />
                  - Communicate with you, either directly or through one of our partners,
                  including for customer service, to provide you with updates and other
                  information relating to the website, and for marketing and promotional
                  purposes<br />
                  - Send you emails<br />
                  - Find and prevent fraud<br />
                </p>
                <h2>YOUR RIGHTS</h2>
                <p>
                  We would like to make sure you are fully aware of all of your data
                  protection rights. Every user is entitled to the following [complying to
                  (not restricted to) CCPA Privacy Rights & GDPR Data Protection
                  Rights]:<br />
                  - Right of access - You have the right to be informed whether personal
                  data concerning you are being processed. In this case, you have the
                  right to know (where relevant) the purposes of processing;<br />
                  - the categories of personal data being processed;<br />
                  - the recipients or categories of recipients to whom the personal data
                  have been or will be disclosed, in particular in the case of recipients
                  in third countries or international organizations;<br />
                  - if possible, the planned duration for which the personal data will be
                  stored or, if this is not possible, the criteria for determining this
                  duration;<br />
                  - the existence of a right to obtain the rectification or erasure of
                  personal data concerning him or her, or the restriction of processing by
                  the controller, or a right to object to such processing;<br />
                  - the existence of a right of appeal to a supervisory authority;<br />
                  - if the personal data are not collected from the data subject, any
                  available information on the origin of the data;<br />
                  - the existence of automated decision-making, including profiling,
                  pursuant to Article 22(1) and (4) and, at least in those cases,
                  meaningful information about the logic involved and the scope and
                  intended effects of such processing for the data subject.<br />
                  - The right to rectification - You have the right to request that We
                  correct any information you believe is inaccurate. You also have the
                  right to request Us to complete the information you believe is
                  incomplete.<br />
                  - The right to erasure - You have the right to request that We erase
                  your personal data, under certain conditions.<br />
                  - The right to restrict processing - You have the right to request that
                  We restrict the processing of your personal data, under certain
                  conditions.<br />
                  - The right to object to processing - You have the right to object to Us
                  processing your personal data, under certain conditions.<br />
                  - The right to data portability - You have the right to request that We
                  transfer the data that we have collected to another organization, or
                  directly to you, under certain conditions.<br />
                </p>
                <p>
                  If you make a request, we have one month to respond to you. If you would
                  like to exercise any of these rights, please contact us at our email:
                  info@din.lol
                </p>
                <h2>CHILDREN’S INFORMATION</h2>
                <p>
                  DIN does not knowingly collect any personal identifiable information
                  from children under the age of 13. We encourage parents and guardians to
                  observe, participate in, and/or monitor and guide children’s online
                  activities. If you think that your child provided this kind of
                  information on our website, we strongly encourage you to contact us
                  immediately and we will do our best efforts to promptly remove such
                  information from our records.
                </p>
                <h2>PRIVACY POLICY CHANGES</h2>
                <p>
                  We may change this Privacy Policy from time to time, and at our sole
                  discretion. We will inform you in advance of any material changes. You
                  are encouraged to review this page for the latest version of this
                  Privacy Policy. Your continued use of this site after any changes in
                  this Privacy Policy will constitute your acceptance of such changes.
                </p>

              </div>
            </>
            :
            <>
              <div className={styles.title}>Terms and Conditions</div>
              <div>
                <h2>Last updated: Last updated: May 15th, 2024</h2>
                <p>
                  Please read these Terms and Conditions ( "Terms", "Terms and Conditions"
                  ) carefully before using the DIN websites, including
                  (https://din.lol),(http://xdata.din.lol) and all other subdomains.
                </p>
                <p>
                  Your access to and use of the Service is conditioned on your acceptance
                  of and compliance with these Terms. These Terms apply to all visitors,
                  users and others who access or use the Service.
                </p>
                <p>
                  By accessing or using the Service you agree to be bound by these Terms
                  and Conditions. If you disagree with any part of the terms then you may
                  not access the Service.
                </p>
                <h2>Disclaimer</h2>
                <p>
                  To the maximum extent permitted under applicable law, the Service is
                  provided on an “as is” and “as available” basis. We expressly disclaim,
                  and you waive, all warranties of any kind, whether express or implied,
                  including, without limitation, implied warranties of merchantability,
                  fitness for a particular purpose, title and non-infringement as to the
                  Service, including the information, content and materials contained
                  therein.
                </p>

                <h2>Acceptable Use</h2>
                <p>
                  You agree to use the Service in accordance with the applicable law and
                  regulations and in accordance with these Terms. You are solely
                  responsible for your conduct while using the Service. You must not:
                </p>
                <p>
                  1. Copy, reproduce, modify, distribute, duplicate, copy, sell, resell,
                  license, publicly display or disclose the Service without our prior
                  consent.<br />
                  2. Share, recompile, decompile, disassemble, reverse engineer, or make
                  or distribute any other form of, or any derivative work from the
                  Service;<br />
                  3. Use the Service, directly or indirectly, in any manner that could
                  cause the Service so used to: (i) be a substitute for the Service by a
                  third party, (ii) affect DIN’s ability to realize revenue in
                  connection with the Service, or (iii) compete with DIN's business;<br />
                  4. Store or use data or content in an archival file site, database or
                  other searchable repository except as expressly permitted by this
                  agreement or in an applicable order form.<br />
                  5. Use any robot, spider, crawler, scraper or other automated means or
                  interface not provided by us to access the Service or to extract
                  data;<br />
                  6. Use or attempt to use another user’s account without
                  authorization;<br />
                  7. Attempt to circumvent any content filtering techniques we employ, or
                  attempt to access any service or area of the Service that you are not
                  authorised to access;<br />
                  8. Introduce to the Service any virus, trojan worms, logic bombs or
                  other harmful material or otherwise hack the Service;<br />
                  9. Develop any third-party applications that interact with the Service
                  without our prior written consent;<br />
                </p>
                <p>
                  We reserve the right to determine whether a use of the Service is in
                  violation of these Terms or fails to constitute acceptable use.
                </p>
                <h2>Accessing DIN using Web3 Services:</h2>
                <p>
                  DIN leverages web3 services to provide our end users the ability to
                  sign up with, or associate their existing account, to their blockchain
                  address which functions as an extension of their personal identity and
                  can be used to as a form of accessing the platform.
                </p>
                <p>
                  By using this feature DIN assumes the right to collect your
                  blockchain wallet address, completed transaction information and token
                  identifiers for the purpose of enabling this feature.
                </p>
                <p>
                  Wallets are not maintained by, operated by, or affiliated with DIN.
                  DIN has no control over the contents of your wallet and does not
                  accept any responsibility for, or liability to you, in connection with
                  your usage of a wallet on the DIN platform. You, as the user and
                  owner of the wallet, are solely responsible for keeping your wallet
                  secure. You should never share credentials or seed phrases of your
                  wallet with anyone and DIN will never ask for this information under
                  any circumstances. Any issues noted with your wallet, should be directed
                  to and discussed with your wallet provider.
                </p>
                <h2>Third-Party Links</h2>
                <p>
                  The Service may contain links to third-party websites or services that
                  are not owned or controlled by DIN.
                </p>
                <p>
                  DIN has no control over, and assumes no responsibility for, the
                  content, privacy policies, or practices of any third party web sites or
                  services. You further acknowledge and agree that DIN shall not be
                  responsible or liable, directly or indirectly, for any damage or loss
                  caused or alleged to be caused by or in connection with use of or
                  reliance on any such content, goods or services available on or through
                  any such web sites or services.
                </p>
                <p>
                  We strongly advise you to read the terms and conditions and privacy
                  policies of any third-party websites or services that you visit.
                </p>
                <h2>Intellectural Property Rights</h2>
                <p>
                  We retain all rights, including all copyright and other intellectual
                  property rights, in all content and other materials contained on our
                  website or provided in connection with the Service.
                </p>
                <p>
                  ‍You receive no license or usage rights to the Service other than what
                  is explicitly stated in these Terms.
                </p>
                <h2>Limitation</h2>
                <p>
                  Except as otherwise required by law, in no event shall we be liable for
                  any special, indirect or consequential damages, or any other damages of
                  any kind, including but not limited to loss of use, loss of profits or
                  loss of data, arising out of or in any way connected with the use of or
                  inability to use the Service, including without limitation any damages
                  resulting from reliance by you on any information obtained from using
                  the Service.
                </p>
                <h2>Maintenance</h2>
                <p>
                  We may temporarily suspend the Service from time to time for
                  maintenance. We do not warrant any particular level of Service
                  availability.
                </p>

                <h2>Termination</h2>
                <p>
                  We may terminate or suspend access to the Service immediately, without
                  prior notice or liability, for any reason whatsoever, including without
                  limitation if you breach the Terms.
                </p>
                <p>
                  All provisions of the Terms which by their nature should survive
                  termination shall survive termination, including, without limitation,
                  ownership provisions, warranty disclaimers, indemnity and limitations of
                  liability.
                </p>
                <h2>Waiver</h2>
                <p>
                  Our failure to enforce any right or provision of these Terms will not be
                  considered a waiver of those rights. If any provision of these Terms is
                  held to be invalid or unenforceable by a court, the remaining provisions
                  of these Terms will remain in effect. These Terms constitute the entire
                  agreement between us regarding the Service, and supersede and replace
                  any prior agreements we might have between us regarding the Service.
                </p>
                <h2>Changes</h2>
                <p>
                  We reserve the right, at our sole discretion, to modify or replace these
                  Terms at any time. If a revision is material we will try to notify you
                  prior to any new terms taking effect. What constitutes a material change
                  will be determined at our sole discretion. By continuing to access or
                  use the Service after those revisions become effective, you agree to be
                  bound by the revised terms. If you do not agree to the new terms, please
                  stop using the Service.
                </p>
                <h2>Govering Law and Jurisdiction</h2>
                <p>
                  These Terms shall be governed and construed in accordance with the laws
                  of Singapore, without regard to its conflict of law provisions.
                </p>
                <p>
                  You irrevocably agree that the Singapore courts shall have non-exclusive
                  jurisdiction to hear and decide any suit, action or proceedings or
                  settle any dispute arising out of or in connection with the Service.
                </p>
                <h2>Contact Us</h2>
                <p>
                  If you have any questions about these Terms, please contact us via
                  email:
                </p>
                <p>info@din.lol</p>
              </div>
            </>
        }
      </div>

    </Modal>
  </div >
}

export default Layout;
