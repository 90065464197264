import { useState } from 'react';
import styles from './index.module.scss'
import cx from 'classnames'

function products() {
  const [activeNav, setActiveNav] = useState(1)
  return <div className={styles.page}>
    <div className={styles.bigTitle}>Product</div>
    <div className={styles.main}>
      <div className={styles.mLeft}>
        <div className={styles.tRow}>
          <img src="/images/xData.png" alt="" />
          <a href="https://xdata.din.lol/" target="_blank" className={cx(styles.btn, 'hvr-float-shadow')}>Explore &gt;</a>
        </div>
        <div className={styles.text}>AI data collection node infrastructure</div>
        <div className={styles.iL}>
          <div className={styles.row}>
            <img src="/images/n31.png" alt="" />
            <span>Easy collect, easy earn</span>
          </div>
          <div className={styles.row}>
            <img src="/images/n32.png" alt="" />
            <span>Self-sovereign curation</span>
          </div>
          <div className={styles.row}>
            <img src="/images/n33.png" alt="" />
            <span>Decentralized storage</span>
          </div>
        </div>
      </div>
      <div className={styles.mRight}>
      <img className={styles.n3b} src="/images/n3b.png" alt="" />

        <div className={styles.mRR}>
          <div className={styles.businessWrap}>
            <span>Extensions</span>
            <div className={cx(styles.icons, styles.linkWrap)}>
              <a className='hover-item' href="https://chromewebstore.google.com/detail/xdata/ajgmddbjkeopdfodegmglhgjbdoafnmm" target="_blank">
                <img className={styles.bnbChain} src="/images/chrome.png" alt="" />
              </a>
              <a className='hover-item' href="https://chromewebstore.google.com/detail/xdata/ajgmddbjkeopdfodegmglhgjbdoafnmm" target="_blank">
                <img className={styles.bnbChain} src="/images/edge.png" alt="" />
              </a>
            </div>
          </div>
          <div className={styles.dash}>
            <img src="/images/dash.svg" alt="" />
          </div>
          <div className={styles.partners}>
            <div className={styles.title}>Partners</div>
            <div className={styles.list}>
              <div className={styles.item}>
                <img src="/images/bnb.png" alt="" />
                <span>BNB chain</span>
              </div>
              <div className={styles.item}>
                <img src="/images/opbnb.png" alt="" />
                <span>opBNB</span>
              </div>
              <div className={styles.item}>
                <img src="/images/greenfield.png" alt="" />
                <span>BNB Greenfield</span>
              </div>

            </div>
          </div>
          <div className={styles.dash}>
            <img src="/images/dash.svg" alt="" />
          </div>
          <div className={styles.links}>
            <span>Links</span>
            <div className={styles.icons}>
              <a className='hvr-bounce-in' href="https://twitter.com/din_lol_" target="_blank">
                <img src="/images/twi.svg" alt="" />
              </a>
              <a className='hvr-bounce-in' href="https://dinlol.gitbook.io/xdata/" target="_blank">
                <img src="/images/doc.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div >
}

export default products;
