import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './pages/layout';
import Home from './pages/home';
import About from './pages/about';
import Products from './pages/products';
import Career from './pages/career';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="products" element={<Products />} />
        <Route path="career" element={<Career />} />
        <Route path="*" element={<Navigate to="/" replace />} /> {/* 处理不存在的路由 */}
      </Route>
    </Routes>
  );
}
